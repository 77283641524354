<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Rich Text Editor</p>
      <v-container>
        <v-row justify="space-between" class="my-2">
          <v-col class="mr-3 container-style">
            <div class="d-flex justify-center">
              <p class="section-title mb-0 text-center mt-2 mr-2">
                Default RTE
              </p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Default RTE')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </div>
            <div class="pa-4">
              <!-- RTE -->
              <RichTextEditor v-model="content" :placeholder="placeholder" />
              <!-- RTE -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import RichTextEditor from '@/components/shared/customComponents/richTextEditor/Tiptap.vue';

export default {
  name: 'RichTextEditorWiki',
  components: {
    RichTextEditor,
  },

  data: () => ({
    content: null,
    placeholder: 'Write something ...',
  }),

  methods: {
    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = `<RichTextEditor v-model="content" :placeholder="placeholder" />`;
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
